class RequestError extends Error {
  status: number = 400;

  constructor(status: number) {
    super();

    this.status = status;
  }
}

class ApiSink {
  public loading: boolean;

  constructor() {
    this.loading = false;
  }

  public getSink() {
    return fetch("/api/v1/sink", {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.status >= 400) {
        throw new RequestError(response.status);
      }

      return response.json();
    });
  }

  public getLayers(surveyId: number) {
    return fetch(`/api/v1/surveys/${surveyId}/layers`, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }

  public getAnnotations(layerId: number) {
    return fetch(`/api/v1/layers/${layerId}/annotations`, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }

  public getAnnotatedLayers(surveyId: number) {
    return fetch(`/api/v1/surveys/${surveyId}/annotations`, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }

  public getDownloads(surveyId: number) {
    return fetch(`/api/v1/surveys/${surveyId}/downloads`, {
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    }).then((response) => response.json());
  }
}

export default ApiSink;
